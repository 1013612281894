import React from 'react';

interface UserStatues { user_id: number; displayname: string; status: string }
interface ChannelData { UserStatues?: UserStatues[] }
interface Members { channelData?: ChannelData }

const members: React.FC<Members> = ({ channelData }) => {
    const users = channelData?.UserStatues || [];

    const onlineUsers = users.filter(user => user.status !== 'offline');
    const offlineUsers = users.filter(user => user.status === 'offline');
  
    return (
      <div className="members-sidebar">
        <div className="members-group">
          ONLINE <span className="group-counter">{onlineUsers.length}</span>
        </div>
  
        {onlineUsers.map(user => (
          <div key={user.user_id} className={`member ${user.status}`}>
            <div className="member-avatar"><img className='profile-avatar' src={`/pfp/${user.user_id}.png`} alt="" /></div>
            <div className="member-name">{user.displayname}</div>
            <div className="status-indicator"></div>
          </div>
        ))}
  
        <div className="members-group">
          OFFLINE <span className="group-counter">{offlineUsers.length}</span>
        </div>
  
        {offlineUsers.map(user => (
          <div key={user.user_id} className={`member ${user.status}`}>
            <div className="member-avatar"><img className='profile-avatar' src={`/pfp/${user.user_id}.png`} alt="" /></div>
            <div className="member-name">{user.displayname}</div>
            <div className="status-indicator"></div>
          </div>
        ))}
      </div>
    );
  };

export default members;