import React, {useRef,useEffect, useState} from 'react';

interface wsMessage { op: string; d: {}, success?: boolean, error?: string }
interface User { user_id: number; username: string; displayname: string; email: string; pfp: string }
interface ChannelInfo { name: string; description: string }
interface Reaction {type: string; count: number, reactors: User[] }
interface Message { message_id: number | string; user_id: number; content: string; timestamp: string; username: string; reactions: Reaction[]; }
interface ChannelData { channelinfo?: ChannelInfo; messages?: Message[] }
interface Channel {channel_id: number; name: string; description: string; created_at: Date; guild_id: number; type: 'voice' | 'text' }

interface channels { channelData: ChannelData; user: User | false; selectedChannel: Channel | null; wsSend: (message: wsMessage) => void; sendMessage: (message: Message) => void }

const ChannelOpen: React.FC<channels> = ({ channelData, selectedChannel, sendMessage, user, wsSend }) => {
    const [message, setMessage] = useState<string>('');
    const messagesEndRef = useRef<HTMLDivElement | null>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [channelData]);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && message.trim() !== '') {
            if (!user) { return }
            if (!selectedChannel?.channel_id) { return }
            const tempId = 'temp-' + Date.now();
            const messageSent: Message = {message_id: tempId, user_id: user.user_id, content: message, timestamp: "just now", username: user.username, reactions: []} 
            sendMessage(messageSent);
            wsSend({op: "sendMessage", d: {...messageSent, channelId: selectedChannel?.channel_id}})
            setMessage('');
        }
    };
    
    return (
        <div className="main-content" id="open-channel">
            <div className="chat-header">
                <h2>{channelData.channelinfo?.name}</h2>
                <p>{channelData.channelinfo?.description}</p>
            </div>

            <div className="messages-area" id="messages">
                {!selectedChannel && 
                    <div className='no-channels'>
                        <i className="bi bi-cone-striped"></i>
                        <h1>No Server or Channel Selected</h1>
                        <p><strong>To join servers,</strong> simply accept an invite or use the discovery tool to explore community servers you may be interested in joining.</p>
                        <p><strong>To create a new server,</strong> click the plus icon in the bottom left corner of your screen.</p>
                        <p><strong>To add channels,</strong> right-click in the channels section and select "Create Channel" from the options.</p>
                    </div>
                }
                {channelData.messages && channelData.messages.map((message) => (
                    <div className="message" key={message.message_id} data-message-id={message.message_id}>
                        <div className="user-avatar">
                            <img src={`/pfp/${message.user_id}.png`} alt="User Avatar" />
                        </div>
                        <div className="message-content">
                            <div className="message-header">
                            <span className="user-name">{message.username}</span>
                            <span className="timestamp">{message.timestamp}</span>
                            </div>
                            <div className="message-text">{message.content}</div>
                            <div className="reactions">
                            {message.reactions.map((reaction, index) => (
                                <span key={index} className="reaction">
                                {reaction.type} {reaction.count}
                                </span>
                            ))}
                            </div>
                        </div>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>

            <div className="chat-input-container">
                <input type="text" className="chat-input" placeholder='Try saying "hello guys!"' value={message} onChange={(e) => setMessage(e.target.value)} onKeyPress={handleKeyPress}/>
            </div>
        </div>
    );
};

export default ChannelOpen;
