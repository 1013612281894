import React from 'react';

interface Guild {name: string; guild_id: number, description: string, owner_id: string } 
interface Channel {channel_id: number; name: string; description: string; created_at: Date; guild_id: number; type: 'voice' | 'text' }
interface ChannelList { guildChannels: Channel[]; selectedChannel: number | false; selectedGuild: Guild | null; setSelectedChannel: (Channel: Channel) => void; getChannelById: (channelId: number) => Channel | undefined }

const channels: React.FC<ChannelList> = ({ guildChannels, selectedChannel, setSelectedChannel, getChannelById, selectedGuild }) => {
  return (
    <div className="channels-sidebar">
        <div className="channels-container">
            <div className="channel-category">
                Text channels
                <span>+</span>
            </div>
            {guildChannels.map((channel) => {
                const { channel_id, name, type } = channel;
                const channelTypeSymbol = type === 'voice' ? '🔊' : '#';
                const isSelected = selectedChannel === channel_id;

                const handleClick = type === 'text' ? () => {
                    const selectedChannel = getChannelById(channel_id);
                    if (selectedChannel) {
                      setSelectedChannel(selectedChannel);
                    }
                  }
                : undefined;

                return (
                    <button key={channel_id} 
                    onClick={handleClick} 
                    style={{ cursor: type === 'text' ? 'pointer' : 'default' }}>
                        <div id={isSelected ? 'selected-channel' : undefined} className="channel">
                        {channelTypeSymbol} {name}
                        </div>
                    </button>
                );
            })}
        </div>
        <div className='server-banner'>
            <img className='banner-img' src="https://cdn.discordapp.com/banners/1242297152970166282/9c29762c2f22985bf4faf9714bc8bd5f.webp?size=240" alt="" />
        </div>
        <div className="server-header">
            {selectedGuild?.name}
            <div className="avatar-container">
                <img
                    className="profile-avatar"
                    src={`/pfp/${selectedGuild?.owner_id}.png`}
                    alt="Profile Avatar"
                />
                <img
                    className="crown-icon"
                    src="crown.png"
                    alt="Crown"
                />
            </div>
        </div>
    </div>
    );
};

export default channels;
