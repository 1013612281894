import React from 'react';

interface promptData { header: string, subtext: string, responseText: string, cancelText: string; submitText: string; inputPlaceholder: string }
interface PromptMenu { promptData: promptData }
const promptMenu: React.FC<PromptMenu> = ({ promptData }) => {
    return (
        <div className="prompt-overlay">
            <div className="prompt-container">
                <h1>{promptData.header}</h1>
                <p>{promptData.subtext}</p>
                <label htmlFor="promptInput">{promptData.responseText}</label>
                <input type="text" placeholder={promptData.inputPlaceholder} name="promptInput" />
                <button>{promptData.cancelText}</button>
                <button>{promptData.submitText}</button>
            </div>
        </div>
    );
};

export default promptMenu;