import {useEffect, useState } from 'react';

interface UserStatues { user_id: number; displayname: string; status: string }
interface User { user_id: number; username: string; displayname: string; email: string; pfp: string }
interface Reaction {type: string; count: number, reactors: User[] }
interface Message { message_id: number | string; user_id: number; content: string; success?: string; timestamp: string; username: string; reactions: Reaction[]; }

const useWebSocket = (url: string, updateStatuses: (statuses: UserStatues[]) => void, sendMessage: (message: Message) => void, setFriends: (friends: UserStatues[]) => void) => {
    const [ws, setWs] = useState<WebSocket | null>(null);
    const [wsReady, setWsReady] = useState<Promise<void>>();

    useEffect(() => {
        const token = getCookie('token');
        console.log(token)
        if (!token) {
            setWsReady(Promise.reject("Token not found").catch(error => error));
            return;
        }
        
        const websocket = new WebSocket(url);
        setWs(websocket);

        const wsReadyPromise = new Promise<void>((resolve, reject) => {
            websocket.onopen = () => {
                const token = getCookie('token');
                websocket.send(JSON.stringify({ op: "Authentication", d: {token: token} }));
            };

            websocket.onerror = (err) => {
                console.error('WebSocket error:', err);
                reject(err);
            };

            websocket.onmessage = (event) => {
                const response = JSON.parse(event.data);
                if (response.op === "heartbeat") {
                    websocket.send(JSON.stringify({ op: 'heartbeat', d:{}}));
                }else if (response.op === "Authentication") {
                    if (!response.success) {
                        console.error("Failed to authenticate:", response.error);
                        reject(response.error);
                    } else {
                        resolve();
                    }
                }else if (response.op === "fetchOnline") {
                    if (!response.success) {
                        console.error(response.error);
                    } else {
                        updateStatuses(response.d.users)
                    }
                }else if (response.op === "Message") {
                    sendMessage(response.d)
                }else if (response.op === "subscribe") {

                }else if (response.op === "sendMessage") {

                }else if (response.op === "fetchFriends") {
                    if (!response.success) {
                        console.error(response.error);
                    } else {
                        setFriends(response.d.users)
                    }
                }
            };
        });

        setWsReady(wsReadyPromise);

        return () => {
            websocket.close();
        };
    }, [url, updateStatuses, sendMessage, setFriends]);

    return { ws, wsReady };
};

const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
};

export default useWebSocket;