import React from 'react';

interface Guild {name: string; guild_id: number, description: string, owner_id: string } 
interface User { user_id: number; username: string; displayname: string; email: string; pfp: string }
interface header {guildList: Guild[]; selectedGuild: number | false; user: User | boolean, serverCreate: () => void; serverWheel: () => void; setSelectedGuild: (guild: Guild) => void; opeSetings: () => void } 

const Header: React.FC<header> = ({ guildList, selectedGuild, serverCreate, serverWheel, opeSetings, user, setSelectedGuild }) => {
    return (
        <div className="header">
            <div className="servers-bar">
                <img style={{height: "100%"}} src="logo.png" alt="" />
                <div className="server-maneger">
                    <button onClick={serverCreate} className="server-button"><i className="bi bi-plus-lg"></i></button>
                    <button onClick={serverWheel} className="server-button" id="radial-menu-icon"><i className="bi bi-three-dots-vertical"></i></button>
                </div>

                {guildList.map((guild) => {
                    const guildIcon = guild.name[0];
                    const isSelected = guild.guild_id === selectedGuild;
                    
                    return (
                        <button onClick={() => {
                            setSelectedGuild(guild);
                        }}>
                            <div id={isSelected ? 'selected-guild' : undefined} className="server-container">
                                <div className="server-icon-wrapper">
                                    <div className="server-icon">{guildIcon}</div>
                                </div>
                                <div className="server-bubble"> 
                                    <div className="new-message">Új üzenet (99+)</div>
                                    <div className="server-name">{guildIcon}</div>
                                </div>
                            </div>
                        </button>
                    );
                })}

                <div className="profile-section">
                    <div className="profile-avatar"><img src={user && typeof user === "object" ? user.pfp : "Guest"} alt=''/></div>
                    <div className="profile-info">
                        <div className="profile-name"> {user && typeof user === "object" ? user.username : "Guest"} </div>
                        <div className="profile-tag">{user && typeof user === "object" ? user.displayname : "null"}</div>
                        </div>
                    <div className="profile-controls">
                        <div className="profile-icon"><i className="bi bi-mic-fill"></i></div>
                        <div className="profile-icon"><i className="bi bi-headphones"></i></div>
                        <div onClick={opeSetings} id="settings-icon" className="profile-icon"><i className="bi bi-gear-fill"></i></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;