import React, { useCallback, useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './style/index.css';
import webSocket from './components/ws'

import Header from './components/Header';
import ServerList from './components/ServerList';
import Settings from './components/Settings';
import Channels from './components/Channels';
import OpenChannel from './components/OpenChannel' 
import Members from './components/Members'
import Sidebar from './components/Sidebar'
//import ContextMenu from './components/ContextMenu';
import Promt from './components/promptMenu';

const Login = React.lazy(() => import('./components/Login'));
const EmailConfirm = React.lazy(() => import('./components/emailConfirm'));



interface Guild {name: string; guild_id: number, description: string, owner_id: string } 
interface Channel {channel_id: number; name: string; description: string; created_at: Date; guild_id: number; type: 'voice' | 'text' }
interface ChannelInfo { name: string; description: string }
interface Message { message_id: number | string; user_id: number; content: string; success?: string; timestamp: string; username: string; reactions: Reaction[]; }
interface Reaction {type: string; count: number, reactors: User[] }
interface User { user_id: number; username: string; displayname: string; email: string; pfp: string }
interface UserStatues { user_id: number; displayname: string; status: string }

interface wsMessage { op: string; d: {}, success?: boolean, error?: string }
interface initResponse { success: boolean; error?: string; user: User; login_required?: boolean; selected_guild: number; selected_channel: number; user_guilds: Guild[]; guild_channels: Channel[] }
interface ChannelData { channelinfo?: ChannelInfo; messages?: Message[], UserStatues?: UserStatues[] }



const App: React.FC = () => {
  const [prompt, setpromt] = useState({ header: "fasz kell?", subtext: "kell a fasz te fasszopó geciláda szájbabaszott kutya kurva gecci he?", responseText: "Your Response", inputPlaceholder: "kurva", cancelText: "NEM", submitText: "IGEN" })
  const [promptVisible, setPromptVisible] = useState(false)

  const [channelList, setChannelList] = useState<Channel[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<Channel | null>(null);

  const [guildList, setGuildList] = useState<Guild[]>([]);
  const [selectedGuild, setSelectedGuild] = useState<Guild | null>(null);

  const [channelData, setChannelData] = useState<ChannelData>({})
  const [friends, setFriends] = useState<UserStatues[]>([])

  const [linkDiscord, setLinkDiscord] = useState<string | null>(null);
  const [user, setUser] = useState<User | false>(false)
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginRequired, setLoginRequired] = useState(false)

  const [serverListVisible, setServerListVisible] = useState(false);
  const serverWheel = useCallback(() => {setServerListVisible(!serverListVisible)}, [serverListVisible]);
  
  const [settingsVisible, setSettingsVisible] = useState(false)
  const opeSetings = useCallback(() => {setSettingsVisible(!settingsVisible)}, [settingsVisible])
  
  const getChannelById = useCallback((id: number) => channelList.find(channel => channel.channel_id === id), [channelList]);
  const getGuildById = useCallback((id: number) => guildList.find(guild => guild.guild_id === id), [guildList]);
  
  const sendMessage = useCallback((message: Message) => {setChannelData(prevChannelData => ({...prevChannelData, messages: [...(prevChannelData.messages || []), message]}))}, [])
  const updateStatuses = useCallback((UserStatues: UserStatues[]) => {setChannelData(prevChannelData => ({...prevChannelData,UserStatues: [...UserStatues]}))}, [])


  const {ws, wsReady } = webSocket('wss://ws.chat.codeweave.hu', updateStatuses, sendMessage, setFriends);
  const wsSend = useCallback(async(message: wsMessage) => {
    try {
      await wsReady; 
      if (ws) { 
        ws.send(JSON.stringify(message))
      } else {
        console.error("WS not set");
      }
    } catch (error) {
      if (!loginRequired || loggedIn) {
        console.error("WebSocket connection failed:", error)
      } 
    }
  }, [ws, wsReady, loginRequired, loggedIn]);

  const serverCreate = useCallback(() => {
    
  }, [])
  
  const logout = useCallback(async() => {
    const response = await fetch(`api/logout.php`);
    if (response.status === 200) {
      window.location.reload();
    }
  }, [])

  

  useEffect(() => {
    if (!selectedGuild) { return }
    const fetchChannelData = async () => {
      const response = await fetch(`api/fetchGuild.php?g=${selectedGuild.guild_id}`);
      const data: Channel[] = await response.json();
      setChannelList(data)
      if (data[0].channel_id !== selectedChannel?.channel_id) {
        setSelectedChannel(data[0] || null)
      }
    }
    fetchChannelData()
     .catch(console.error);
  }, [selectedGuild, selectedChannel])

  useEffect(() => {
    if (!selectedChannel) { return }
    const fetchChannelData = async () => {
      const response = await fetch(`api/fetchChannel.php?c=${selectedChannel.channel_id}`);
      const data: ChannelData = await response.json();
      setChannelData(data) 
    }
    fetchChannelData()
      .catch(console.error);
    wsSend({op:"fetchOnline", d: {channelId: selectedChannel.channel_id}})
    wsSend({op: "subscribe", d: {channelId: selectedChannel.channel_id}})
  }, [selectedChannel, wsSend])
  
  useEffect(() => {
    const requestInitData = async () => {
        const response = await fetch('https://chat.codeweave.hu/api/init.php');
        if (!response.ok) { throw new Error(`Error: ${response.statusText}`)}
        const jsonData: initResponse = await response.json();
        if (jsonData.success) {
          setChannelList(jsonData.guild_channels);
          setGuildList(jsonData.user_guilds);
          setUser(jsonData.user)

          const selectedGuildData = jsonData.user_guilds.find((guild) => guild.guild_id === jsonData.selected_guild) || null;
          setSelectedGuild(selectedGuildData);
          const selectedChannelData = jsonData.guild_channels.find((channel) => channel.channel_id === jsonData.selected_channel) || null;
          setSelectedChannel(selectedChannelData);

          setLoggedIn(true)
        } else {
          if (jsonData.login_required) {
            setLoginRequired(true)
          }else{
            throw new Error('Failed to fetch data');
          }
        }

      const queryParams = new URLSearchParams(window.location.search);
      const linkDiscordParam = queryParams.get('link-discord');
      setLinkDiscord(linkDiscordParam);
    };
    requestInitData()
      .catch(console.error);
  }, []);


  
  return (
    loggedIn ? (
      <div className='container'>
        {serverListVisible && <ServerList serverWheel={serverWheel} />}

        <Header
          guildList={guildList}
          selectedGuild={false}
          user={user}
          
          serverWheel={serverWheel}
          serverCreate={serverCreate}
          setSelectedGuild={setSelectedGuild}
          opeSetings={opeSetings}
        />

        {settingsVisible ? <Settings user={user} logout={logout}/> : (
          
        <div id="main-container" className="main-container">
          {promptVisible && <Promt promptData={prompt} />}
          <Sidebar
            friends={friends}
          />

          <Channels 
            guildChannels={channelList}
            selectedChannel={false}
            setSelectedChannel={setSelectedChannel}
            getChannelById={getChannelById}
            selectedGuild={selectedGuild}
          />

          <OpenChannel
            selectedChannel={selectedChannel}
            channelData={channelData}
            user={user}
            sendMessage={sendMessage}
            wsSend={wsSend}
          />

          <Members 
            channelData={channelData}
          /> 

          {/*<ContextMenu />*/}
        </div>  
        )}
      </div>
    ) : (
      linkDiscord ? <EmailConfirm email={linkDiscord}/> : (
        loginRequired ? <Login/> : <div className="loaderparent"><span className="loader"></span></div>
      )      
    )
  );
}

export default App;